/**
 * Postuler page
 */
/* Import section */
import * as React from "react"
import { graphql } from "gatsby"
import Blockbenefitrow from "../../../components/blockbenefitrow"
import Formcareer from "../../../components/formcareer"
import Geturlparameter from "../../../components/geturlparameter"
import Herotype2 from "../../../components/herotype2"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Titledecorative from "../../../components/titledecorative"

/* Declaration */
const PostulerPage = ({ data, search }) => {
  const { job } = search
  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageApplyFr
  var tLinkSlug= "/careers/"+dataPage.translations[0].slug
  var repeaterInfos = dataAcf.repeaterInfosApplyFr

  console.log("param:"+job)

  return(
    <Layout tLinkSlug={tLinkSlug}>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        tLinkSlug={tLinkSlug} />

      <section className="section-hero-general section-herotype2">
        <Herotype2 h1title={dataAcf.titleH1ApplyFr} classImg="job" />
      </section>
      <section className="section-contact flex-section-two">
        <div className="content-form flex-content-two">
          <div className="wrap-form flex-wrap-two">
            <Titledecorative titleType="h2" title={dataAcf.titleFormApplyFr} colorBar="light" />
            <Formcareer param={job} />
          </div>
        </div>

        <div className="content-infos flex-content-two blue-box">
          <div className="wrap-infos flex-wrap-two">
            <Titledecorative titleType="h2" title={dataAcf.titleInfosApplyFr} colorBar="marin" />
            <div>
              {repeaterInfos.map((info, index) =>
                <Blockbenefitrow
                  keyBenefit={index}
                  textBenefit={info.textInfoApplyFr}
                  iconBenefit={info.iconInfoApplyFr.localFile.childImageSharp.gatsbyImageData}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpPage(slug: {regex: "/postuler/"}) {
      acfPageApplyFr {
			  titleInfosApplyFr
        titleH1ApplyFr
        titleFormApplyFr
        repeaterInfosApplyFr {
          iconInfoApplyFr {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP], width: 200)
              }
            }
          }
          textInfoApplyFr
        }
      }
      seo {
        metaDesc
        title
      }
      translations {
        slug
      }
    }
  }
`

export default Geturlparameter(PostulerPage)
